import { RequestService } from "@/api/instance";
import { DeAuthSession } from "@/api/de-auth";

export const GetResponses = async (id, search_term, cqc_rating) => {
  let url = `/proposals/enquiry/${id}`;
  let filters = [];
  if (cqc_rating != null && cqc_rating.length != 0) {
    filters.push(`rating=${cqc_rating.join(",")}`);
  }
  if (search_term != null && search_term != "") {
    filters.push(`term=${search_term}`);
  }
  url = filters.length > 0 ? (url += "?" + filters.join("&")) : url;
  try {
    let {
      data: { proposals }
    } = await RequestService().get(url);
    proposals.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    return proposals;
  } catch (e) {
    DeAuthSession(e);

    throw e;
  }
};
