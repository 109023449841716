<template>
  <v-card class="responses-container">
    <v-toolbar dark color="primary">
      <v-toolbar-title class="title">Responses</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-card-actions>
        <v-btn icon @click="show = !show">
          <v-icon>mdi-tune</v-icon>
        </v-btn>
      </v-card-actions>
    </v-toolbar>
    <v-expand-transition>
      <div v-show="show">
        <v-card-title id="filter-title"
          >Filter your search requests</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="search"
            outlined
            label="Search"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
          <v-select
            v-if="show_cqc"
            v-model="cqcFilterChoice"
            :items="cqcFilterOptions"
            label="CQC Rating"
            multiple
            solo
            chips
            ><template v-slot:selection="{ item }">
              <v-chip :color="colors[item]" :text-color="'#000000'" label dark
                ><v-avatar left v-if="item === 'Outstanding'"
                  ><v-icon> stars </v-icon></v-avatar
                >{{ item }}</v-chip
              >
            </template>
            <template slot="item" slot-scope="data">
              <v-icon :color="colors[data.item]" style="margin-right: 5px"
                >circle</v-icon
              >
              {{ data.item }}
            </template>
          </v-select>
          <v-container class="text-right">
            <v-btn
              class="clear-filter-button"
              color="lightGrey"
              @click="clearFilter()"
            >
              Clear
            </v-btn>
            <v-btn medium color="primary" @click="refreshResponses()">
              Apply filter
            </v-btn>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
      </div>
    </v-expand-transition>
    <v-card-text>
      <v-list two-line class="list" v-if="responses.length">
        <v-list-item
          v-for="response in responses"
          :key="response.id"
          ripple
          @click="open(response.id)"
        >
          <v-list-item-avatar>
            <img style="object-fit: cover" :src="response.company_info.logo" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ response.company_info.company_name }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="show_cqc">
              CQC Rating:
              <v-icon
                small
                :color="colors[response.company_info.cqc_rating.overall_rating]"
                style="margin-right: 3px"
                >circle</v-icon
              >{{ response.company_info.cqc_rating.overall_rating }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <small>{{ response.created_at }}</small>
        </v-list-item>
      </v-list>
      <div
        class="searching"
        v-else-if="!responses.length & (searching == true)"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else class="no-responses">
        <p>
          Check back here to see details of
          {{ site.toLowerCase() }} companies who have made an offer. You will
          also receive an email alert when an offer of
          {{ site.toLowerCase() }} is made (this may go to your junk mail/spam
          folder).
        </p>
        <p>
          If you experience any issues please contact our help desk using the
          icon in the bottom right of your screen.
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
// import { BASE_URL } from '@/config'
// import axios from 'axios'
import { SITE, SHOW_CQC } from "@/config";
import { DateTime } from "luxon";
import { GetResponses } from "@/api/requests/responses";
export default {
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      show_cqc: SHOW_CQC,
      show: false,
      search: "",
      searching: false,
      site: SITE,
      panels: [false, false, false],
      panelState: {
        care: false,
        physio: false,
        both: false
      },
      responses: {},
      cqcFilterOptions: [
        "Outstanding",
        "Good",
        "Requires improvement",
        "Inadequate"
      ],
      colors: {
        Outstanding: "#81C784",
        Good: "#458F00",
        "Requires improvement": "#FF9900",
        Inadequate: "#BF1000"
      },
      cqcFilterChoice: []
    };
  },
  async beforeMount() {
    let responses = await GetResponses(
      this.id,
      this.search,
      this.cqcFilterChoice
    );
    for (let r of responses) {
      r.created_at = DateTime.fromISO(r.created_at).toLocaleString(
        DateTime.DATETIME_FULL
      );
      r.company_info.cqc_rating = { overall_rating: null };
    }
    this.responses = responses;
  },
  methods: {
    open(id) {
      this.$router.push({
        name: "response",
        params: { id }
      });
    },
    async refreshResponses() {
      this.searching = true;
      let responses = await GetResponses(
        this.id,
        this.search,
        this.cqcFilterChoice
      );
      for (let r of responses) {
        r.created_at = DateTime.fromISO(r.created_at).toLocaleString(
          DateTime.DATETIME_FULL
        );
      }
      this.responses = responses;
      this.searching = false;
    },
    async clearFilter() {
      this.cqcFilterChoice = [];
      this.search = "";
      this.searching = true;
      let responses = await GetResponses(
        this.id,
        this.search,
        this.cqcFilterChoice
      );
      for (let r of responses) {
        r.created_at = DateTime.fromISO(r.created_at).toLocaleString(
          DateTime.DATETIME_FULL
        );
      }
      this.responses = responses;
      this.searching = false;
    }
  }
};
</script>

<style scoped>
.no-responses {
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
}
.searching {
  width: 100%;
  display: flex;
  justify-content: center;
}
.clear-filter-button {
  margin-right: 10px;
}
.responses-container {
  width: 40%;
  margin: 50px auto;
}

@media only screen and (max-width: 360px) {
  .clear-filter-button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  #filter-title {
    word-break: break-word;
  }
}

@media screen and (min-width: 700px) {
  .responses-container {
    width: 80%;
  }
}

@media screen and (min-width: 1400px) {
  .responses-container {
    width: 65%;
  }
}
</style>
